// config.js
const config = {
    // apiUrl: 'http://localhost:3001',
    apiUrl: 'https://track24.kz:3001',
   

    nameCargo: 'Track24.kz'
  };
  
  
export default config;
  
